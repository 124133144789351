import React from 'react'
import 'twin.macro'
import { Link } from '../../../utils/general'

export default function HeroMarketing() {
    return (
      <div tw="relative bg-white overflow-hidden w-full">
          <div tw="max-w-7xl mx-auto">
            <div tw="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-24 xl:pb-32">
              <svg tw="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                <polygon points="50,0 100,0 50,100 0,100" />
              </svg>

              <main tw="mx-auto max-w-7xl px-4  sm:px-6 lg:px-8 ">
                <div tw="sm:text-center lg:text-left pt-20">
                  <h1 tw="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span tw="block xl:inline">Unique, Transparent<br /></span>
                    <span tw="block text-secondary-900 xl:inline">Marketing Services</span>
                  </h1>
                  <p tw="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">We’re full service which means we’ve got you covered from start to finish for all your marketing services. Dedicated to creating uniquely your services programs and building a long-lasting relationship.</p>
                  <div tw="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div tw="rounded-md shadow">
                      <Link to="/pages/about-us" tw="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white hover:text-white bg-secondary-900 hover:no-underline hover:bg-secondary-700 md:py-4 md:text-lg md:px-10"> About Us </Link>
                    </div>
                    <div tw="mt-3 sm:mt-0 sm:ml-3">
                      <Link href="/pages/contact-us" tw="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white hover:text-white bg-primary-400 hover:no-underline hover:bg-primary-500 md:py-4 md:text-lg md:px-10"> Contact </Link>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
          <div tw="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img tw="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://cdn.shopify.com/s/files/1/0278/3612/1223/files/her-1.jpg?v=1612766463" alt="" />
      </div>
      </div>

    )
}
